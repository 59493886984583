import React, { useEffect } from "react"
import anime from 'animejs/lib/anime.es.js';
import style from "./EndPage.module.css"

const EndPage = () => {
  const state = {
    num: 60,
    vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  };

  const starryNight = () => {
    anime({
      targets: ["#sky #star"],
      opacity: [
        {
          duration: 700,
          value: "0"
        },
        {
          duration: 700,
          value: "1"
        }
      ],
      easing: "linear",
      loop: true,
      delay: (el, i) => 50 * i
    });
  };
  const shootingStars = () => {
    anime({
      targets: ["#shootingstars #wish"],
      easing: "linear",
      loop: true,
      delay: (el, i) => 1000 * i,
      opacity: [
        {
          duration: 700,
          value: "1"
        }
      ],
      width: [
        {
          value: "150px"
        },
        {
          value: "0px"
        }
      ],
      translateX: 350
    });
  };
  const randomRadius = () => {
    return Math.random() * 0.7 + 0.6;
  };
  const getRandomX = () => {
    return Math.floor(Math.random() * Math.floor(state.vw)).toString();
  };
  const getRandomY = () => {
    return Math.floor(Math.random() * Math.floor(state.vh)).toString();
  };
  useEffect(() => {
    starryNight();
    shootingStars();
  }, [])

  const { num } = state;
  return (
    <>
      <div id="App" className={style.body}>
        <svg id="sky" className={style.sky}>
          {[...Array(num)].map((x, y) => (
            <circle
              cx={getRandomX()}
              cy={getRandomY()}
              r={randomRadius()}
              stroke="none"
              strokeWidth="0"
              fill="white"
              key={y}
              id="star"
              className={style.star}
            />
          ))}
        </svg>
        <div id="shootingstars" className={style.shootingstars}>
          {[...Array(60)].map((x, y) => (
            <div
              key={y}
              id="wish"
              className={style.wish}
              style={{
                left: `${getRandomY()}px`,
                top: `${getRandomX()}px`
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className={style.container}>
        <div className={style.campfire_wrapper}>
          <div className={style.tree_container_back}>
            <div className={style.tree_8}></div>
            <div className={style.tree_9}></div>
            <div className={style.tree_10}></div>
          </div>
          <div className={style.rock_container}>
            <div className={style.rock_big}></div>
            <div className={style.rock_small}>
              <div className={style.rock_1}></div>
              <div className={style.rock_2}></div>
              <div className={style.rock_3}></div>
              <div className={style.rock_4}></div>
            </div>
          </div>
          <div className={style.smoke_container}>
            <svg className={style.svg}>
              <path d="M 150 0 Q 200 100 100 250 C 0 450 120 400 50 600  " />
            </svg>
            <div className={style.fire_container}>
              <div className={style.flame_1}></div>
              <div className={style.flame_2}></div>
              <div className={style.flame_3}></div>
            </div>
          </div>
          <div className={style.tree_container_front}>
            <div className={style.tree_1}></div>
            <div className={style.tree_2}></div>
            <div className={style.tree_3}></div>
            <div className={style.tree_4}></div>
            <div className={style.tree_5}></div>
            <div className={style.tree_6}></div>
            <div className={style.tree_7}></div>
          </div>
        </div>
      </div>

      <div className={style.title_container}>
        <div className={style.title_center}>
          <div className={style.title_box}>
            <span>[다음 5시간 챌린지를 기대해주세요!]</span> 
            <span>진행된 챌린지</span>
            <span>주 1회 이상 달성자 94%</span>
            <span>주 5회 이상 달성자 53%</span>
            <span>주 7회 완주 달성자 24%</span>
            <span>함께 챌린지를 통해 움직이며</span>
            <span>우리는 또 발전했습니다!</span>
          </div>
        </div>
      </div>
    </>
  );
}


export default React.memo(EndPage);